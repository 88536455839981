import React from 'react'

interface ContentFullWidthProps {
  children: React.ReactNode
}

export default function Example({ children }: ContentFullWidthProps) {
  return (
    <div className=" py-16">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Legal
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Privacy Policy
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
          </p>
        </div>
        <div className="mt-6 prose prose-indigo text-gray-500 mx-auto prose-h1:text-3xl prose-h1:pt-4 prose-h2:my-3">
          {children}
        </div>
      </div>
    </div>
  )
}
